import logo from './logo.svg';
import './App.css';
import { Amplify } from 'aws-amplify';
import { list } from 'aws-amplify/storage';
import ImageGallery, {Item} from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import { useState, useEffect } from 'react';
import awsconfig from './amplifyconfiguration.json';
import { Menu, MenuItem, MenuButton, MenuRadioGroup } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/theme-dark.css';
import { Provider, ClapButton } from '@lyket/react';

Amplify.configure(awsconfig);

const BUCKET = process.env.BUCKET_NAME || "legendoftheblackhole-storage-external171217-dev"
const YEAR = new Date().getFullYear();

function App() {
  const [images, setImages] = useState();
  const [prefixes, setPrefixes] = useState();
  const [pre, setPre] = useState();

  async function fetchImages(prefix = YEAR){
    const result = await list({})
    const yearsList = result.items.filter(x => /\d{4}\/$/.test(x.key));
    setPrefixes(yearsList.reduce((arr, obj) => { let key = obj.key.replace("/",""); arr.push(key); return arr; }, []))
    setImages(result.items.filter(x => x.key.replace(`${prefix}/`, "") != "" && x.key.startsWith(prefix)).map( obj => ({ sortOrder: new Date(obj.lastModified),  original: `https://${BUCKET}.s3.amazonaws.com/public/${obj.key}`, thumbnail: `https://${BUCKET}.s3.amazonaws.com/public/${obj.key}`}) ).sort((a, b) => b["sortOrder"] - a["sortOrder"]));
    setPre(prefix);
  };

  function renderImg(img) {
    const arrLoc = img.original.split("/");
    const shortened = arrLoc.slice(-1)[0];
    return (
        <div >
          <img class="image-gallery-image" src={img.original}/>
          <div className="clap">
          <ClapButton style={{ width: "50px", height: "50px" }} id={shortened} namespace="legendoftheblackhole" />
          </div>
        </div>
    )
  }


  useEffect(() => {
    fetchImages();
  }, []);

  return (
  (typeof(images) !== 'undefined') ?
  <div><Provider apiKey="pt_43527fc9222d4febe1b94d4e0cacc8"><h3>Artwork&nbsp;&nbsp;&nbsp;by&nbsp;&nbsp;&nbsp;Juniper<br />{pre}</h3>
  <Menu menuButton={<MenuButton className='btn' >Year</MenuButton>} ><MenuRadioGroup  value={YEAR} onRadioChange={(e) => { fetchImages(e.value);}}>
  {prefixes && prefixes.map( prefix => <MenuItem  type="radio" key={prefix} value={prefix}>{prefix}</MenuItem>)}
  </MenuRadioGroup>
  </Menu>
  <ImageGallery renderItem={renderImg} items={images} /></Provider></div>:
  <div>LOADING</div>
  );
};

export default App;
